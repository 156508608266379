import normalizeUrl from "normalize-url"

const normalize = (string) => {
    let str = string.toLowerCase();

    if (str.includes('patreon.com/user?fan_landing=true&u=')) { return `patreon:${str.split('patreon.com/user?fan_landing=true&u=')[1].split('/')[0]}`}
    if (str.includes('patreon.com/user?u=')) { return `patreon:${str.split('patreon.com/user?u=')[1].split('/')[0]}`}
    if (str.includes('minds.com/register?referrer=')) { return `minds:${str.split('register?referrer=')[1]}`}
    if (str.includes('facebook.com/profile.php?id=')) { return `facebook-user:${str.split('facebook.com/profile.php?id=')[1].split('?')[0]}`}
    if (str.includes('venmo.com/code?user_id=')) { return `venmo:${str.split('venmo.com/code?user_id=')[1].split('?')[0]}`}

    str = normalizeUrl(str, {removeQueryParameters: true});
    
    if (str.includes('rumble.com/c/')) { return `rumble:${str.split('com/c/')[1]}`}
    if (str.includes('rumble.com/user/')) { return `rumble:${str.split('com/user/')[1]}`}
    if (str.includes('gab.com/channel/')) { return `gab:${str.split('gab.com/channel/')[1].split('/')[0]}`}
    if (str.includes('gab.com/')) { return `gab:${str.split('gab.com/')[1].split('/')[0]}`}
    if (str.includes('gab.ai/')) { return `gab:${str.split('gab.ai/')[1].split('/')[0]}`}
    if (str.includes('odysee.com/$/invite')) { return `odysee:${str.split('/$/invite/')[1].split('/')[0]}`}
    if (str.includes('odysee.com/@') && str.includes('?r=')) { return `odysee:${str.split('odysee.com/')[1].split('?')[0]}`}
    if (str.includes('odysee.com/@')) { return `odysee:${str.split('odysee.com/')[1].split('/')[0]}`}
    if (str.includes('t.me/joinchat/')) { return `telegraminvite:${str.split('t.me/joinchat/')[1].split('/')[0]}`}
    if (str.includes('t.me/')) { return `telegram:${str.split('t.me/')[1].split('/')[0]}`}
    if (str.includes('patreon.com/join/')) { return `patreon:${str.split('patreon.com/join/')[1].split('/')[0]}`}
    if (str.includes('patreon.com/')) { return `patreon:${str.split('patreon.com/')[1].split('/')[0]}`}
    if (str.includes('bitchute.com/video/')) { return false }
    if (str.includes('bitchute.com/channel/')) { return `bitchute:${str.split('bitchute.com/channel/')[1].split('/')[0]}`}
    if (str.includes('bitchute.com/')) { return `bitchute:${str.split('bitchute.com/')[1].split('/')[0]}`}
    if (str.includes('.gumroad.com/l/')) { return `gumroad:${str.split('.gumroad.com/l')[0].split('//')[1]}`}
    if (str.includes('gumroad.com/l/')) { return `gumroad:${str.split('gumroad.com/l/')[1].split('/')[0]}`}
    if (str.includes('//gumroad.com/')) { return `gumroad:${str.split('gumroad.com/')[1]}`}
    if (str.includes('gumroad.com')) { return `gumroad:${str.split('.gumroad.com')[0].split('//')[1]}`}
    if (str.includes('.bandcamp.com')) { return `bandcamp:${str.split('.bandcamp.com')[0].split('//')[1]}`}
    if (str.includes('discord.gg/')) { return `discord:${str.split('discord.gg/')[1]}`}
    if (str.includes('discord.com/invite/')) { return `discord:${str.split('discord.com/invite/')[1]}`}
    if (str.includes('subscribestar.com/')) { return `subscribestar:${str.split('subscribestar.com/')[1]}`}
    if (str.includes('buymeacoffee.com/')) { return `buymeacoffee:${str.split('buymeacoffee.com/')[1]}`}
    if (str.includes('ko-fi.com/')) { return `ko-fi:${str.split('ko-fi.com/')[1]}`}
    if (str.includes('minds.com/')) { return `minds:${str.split('minds.com/')[1]}`}
    if (str.includes('cash.app/')) { return `cashapp:${str.split('cash.app/')[1]}`}
    if (str.includes('cash.me/')) { return `cashapp:${str.split('cash.me/')[1]}`}
    if (str.includes('gofundme.com/f/')) { return `gofundme:${str.split('gofundme.com/f/')[1]}`}
    if (str.includes('gofundme.com/')) { return `gofundme:${str.split('gofundme.com/')[1].split('?')[0]}`}
    if (str.includes('gofund.me/')) { return `gofundme:${str.split('gofund.me/')[1].split('?')[0]}`}
    if (str.includes('venmo.com/u/')) { return `venmo:${str.split('venmo.com/u/')[1].split('?')[0]}`}
    if (str.includes('venmo.com/')) { return `venmo:${str.split('venmo.com/')[1].split('?')[0]}`}
    if (str.includes('twitter.com/') && str.includes('status')) { return `twitter:${str.split('twitter.com/')[1].split('/status')[0]}`}
    if (str.includes('twitter.com/')) { return `twitter:${str.split('twitter.com/')[1].split('?')[0]}`}
    if (str.includes('entropystream.live/app/')) { return `entropystream:${str.split('entropystream.live/app/')[1].split('?')[0]}`}
    if (str.includes('entropystream.live/')) { return `entropystream:${str.split('entropystream.live/')[1].split('?')[0]}`}
    if (str.includes('parler.com/profile/')) { return `parler:${str.split('parler.com/profile/')[1].split('/')[0]}`}
    if (str.includes('parler.com/#/user/')) { return `parler:${str.split('parler.com/#/user/')[1].split('?')[0]}`}
    if (str.includes('parler.com/user/')) { return `parler:${str.split('parler.com/user/')[1].split('?')[0]}`}
    if (str.includes('parler.com/')) { return `parler:${str.split('parler.com/')[1].split('?')[0]}`}
    if (str.includes('streamlabs.com/')) { return `streamlabs:${str.split('streamlabs.com/')[1].split('/')[0]}`}
    if (str.includes('twitch.tv/')) { return `twitch:${str.split('twitch.tv/')[1].split('/')[0]}`}
    if (str.includes('dlive.tv/')) { return `dlive:${str.split('dlive.tv/')[1].split('?')[0]}`}
    if (str.includes('cozy.tv/')) { return `cozy:${str.split('cozy.tv/')[1].split('?')[0]}`}
    if (str.includes('tiktok.com/') && str.includes('video')) { return `tiktok:${str.split('tiktok.com/')[1].split('/video')[0]}`}
    if (str.includes('tiktok.com/@')) { return `tiktok:${str.split('tiktok.com/')[1].split('?')[0]}`}
    if (str.includes('gettr.com/user')) { return `gettr:${str.split('gettr.com/user/')[1].split('?')[0]}`}
    if (str.includes('locals.com/member')) { return `locals:${str.split('locals.com/member/')[1].split('?')[0]}`}
    if (str.includes('.locals.com')) { return `locals:${str.split('.locals')[0].split('https://')[1]}`}
    if (str.includes('paypal.me/')) { return `paypal:${str.split('paypal.me/')[1].split('?')[0]}`}
    if (str.includes('paypal.com/paypalme/')) { return `paypal:${str.split('paypal.com/paypalme/')[1].split('?')[0]}`}
    if (str.includes('steemit.com/@')) { return `steemit:${str.split('steemit.com/')[1].split('?')[0]}`}
    if (str.includes('amazon.com/author/')) { return `amazon-author:${str.split('amazon.com/author/')[1].split('?')[0]}`}
    if (str.includes('.wordpress.com')) { return `wordpress:${str.split('.wordpress.com')[0].split('https://')[1]}`}
    if (str.includes('indiegogo.com/projects/')) { return `indiegogo:${str.split('indiegogo.com/projects/')[1].split('/')[0]}`}
    if (str.includes('open.spotify.com/show/')) { return `spotify-show:${str.split('open.spotify.com/show/')[1].split('?')[0]}`}
    if (str.includes('open.spotify.com/artist/')) { return `spotify-artist:${str.split('open.spotify.com/artist/')[1].split('?')[0]}`}
    if (str.includes('soundcloud.com/')) { return `soundcloud:${str.split('soundcloud.com/')[1].split('/')[0]}`}
    if (str.includes('spreaker.com/show/')) { return `spreaker-show:${str.split('spreaker.com/show/')[1].split('/')[0]}`}
    if (str.includes('spreaker.com/user/')) { return `spreaker-user:${str.split('spreaker.com/user/')[1].split('/')[0]}`}
    if (str.includes('givesendgo.com/')) { return `givesendgo:${str.split('givesendgo.com/')[1].split('/')[0]}`}
    if (str.includes('facebook.com/groups')) { return `facebook-group:${str.split('facebook.com/groups/')[1]}`}
    if (str.includes('facebook.com/')) { return `facebook-user:${str.split('facebook.com/')[1]}`}
    if (str.includes('instagram.com/')) { return `instagram:${str.split('instagram.com/')[1]}`}
    if (str.includes('.substack.com')) { return `substack:${str.split('.substack')[0].split('https://')[1]}`}
    if (str.includes('brighteon.com/channels/')) { return `brighteon:${str.split('brighteon.com/channels/')[1]}`}
    if (str.includes('brighteon.com/c/')) { return `brighteon:${str.split('brighteon.com/c/')[1]}`}
    if (str.includes('d.tube/#!/c/')) { return `dtube:${str.split('d.tube/#!/c/')[1]}`}
    if (str.includes('lbry.tv/@')) { return `lbry:${str.split('lbry.tv/')[1]}`}
    if (str.includes('linktr.ee')) { return `linktree:${str.split('linktr.ee/')[1]}`}
    if (str.includes('truthsocial.com/@')) { return `truthsocial:${str.split('truthsocial.com/')[1]}`}
    if (str.includes('vk.com/')) { return `vk:${str.split('vk.com/')[1]}`}
    if (str.includes('donationalerts.com/r/')) { return `donationalerts:${str.split('donationalerts.com/r/')[1]}`}
    if (str.includes('yoomoney.ru/to/')) { return `yoomoney:${str.split('yoomoney.ru/to/')[1]}`}
    if (str.includes('qiwi.com/n/')) { return `qiwi:${str.split('qiwi.com/n/')[1]}`}
    if (str.includes('zen.yandex.ru/id/')) { return `dzen:${str.split('zen.yandex.ru/id/')[1]}`}
    if (str.includes('rutube.ru/channel/')) { return `rutube:${str.split('rutube.ru/channel/')[1]}`}
    if (str.includes('douyin.com/user/')) { return `douyin:${str.split('douyin.com/user/')[1]}`}
    if (str.includes('space.bilibili.com/')) { return `bilibili:${str.split('space.bilibili.com/')[1]}`}
    if (str.includes('weibo.com/u/')) { return `weibo:${str.split('weibo.com/u/')[1]}`}
    if (str.includes('weibo.com/') && str.includes('/profile')) { return `weibo:${str.split('weibo.com/')[1].split('/profile')[0]}`}
    if (str.includes('weibo.com/')) { return `weibo:${str.split('weibo.com/')[1]}`}

    return false;
}

export { normalize }