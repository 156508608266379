<template>
  <div>
    <p class="font-mono text-base base-font-gray-lighter-110 mb-3 leading-none">
      <span>{{ bucketsName | capitalizeFirstLetter }}</span> Buckets
    </p>
    <ul>
      <li 
        v-for="bucket in buckets"
        :key="bucket.id"
        class="mb-4 cursor-pointer flex items-center leading-none"
        :class="[{
          'base-font-gray-lighter-120': !bucket.bucketTypesListActivated,
          'text-white': bucket.bucketTypesListActivated 
        }]"
        @click="submit(bucket)"
      >
        <span 
          v-if="bucket.bucketTypesListActivated"
          class="w-2 h-2 rounded-full bg-white mr-2 inline-block"
        ></span>
        {{ bucket.name }}
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: {
      buckets: {
        type: Array,
        default: () => []
      },
      bucketsName: {
        type: String,
        default: 'Team'
      }
    },
    methods: {
      submit(data){
        this.$emit('submit', { data })
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>